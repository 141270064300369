import React from "react";
import shawnee6 from "../photos/shawnee6.jpg";

export default function Contact() {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 p-4 md:p-8 lg:p-16 scroll-smooth">
        <div className="flex flex-col justify-center">
          <h1 className="text-3xl">Get in touch</h1>
          <br></br>
          <h2 className="text-xl">
            For business inquiries or any questions you might have, please feel
            free to reach me @msshawneeriver on Instagram or via email.
          </h2>
          <br></br>
        </div>
        <div className="flex items-center justify-center">
          <img src={shawnee6} alt="Your Alt Text" className="w-full h-auto" />
        </div>
      </div>
    </>
  );
}
