import "./App.css";
import React from "react";
 import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/navbar";
import Home from "./components/home/home";
import About from "./components/about/about";
import Contact from "./components/about/contact";
import Product from "./components/about/product";
import MyFooter from "./components/footer"; 

function App() {
  return (
    <>
      <NavBar></NavBar>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
      <MyFooter></MyFooter>
    </>
  );
}

export default App;
