import React from "react";
// import ShawneyClimbing from "../photos/ShawneyClimbing.jpg";
import Shawnee1 from "../photos/Shawnee1.jpg";

export default function About() {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 p-4 md:p-8 lg:p-16">
        <div className="flex items-center justify-center">
          <img src={Shawnee1} alt="Your Alt Text" className="w-full h-auto" />
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="text-3xl">Hi, this is Shawnee.</h1>
          <br></br>
          <h2 className="text-xl">
            I am an Earth Based energy healer, Intuitive Eating counselor, and
            Yogi using the power of choice and awareness to help people heal
            from dis-ease.{" "}
          </h2>
          <br></br>
        </div>
        {/* <div className="flex flex-col justify-center">
          <h1 className="text-3xl"></h1>
          <br></br>
          <h2 className="text-xl"></h2>
          <br></br>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={ShawneyClimbing}
            alt="Your Alt Text"
            className="w-full h-auto"
          />
        </div> */}
      </div>
    </>
  );
}
