import { Typography } from "@material-tailwind/react";
import logo from "./photos/logo.jpg";
// import { Link } from "react-router-dom";

// const currentYear = new Date().getFullYear();

const MyFooter = () => {
  return (
    <>
      <footer className="w-full bg-white p-8">
        <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
          <img src={logo} alt="logo-ct" className="w-20" />
          <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
            <li>
              <Typography
                as="a"
                href="/about"
                color="blue-gray"
                className="font-normal transition-colors hover:text-slate-500	 focus:text-blue-500"
              >
                About
              </Typography>
            </li>
            <li>
              <Typography
                as="a"
                href="https://www.etsy.com/listing/1601946495/rad-salve-natural-organic-healing-salve?click_key=ebb7e588bd356a906401ceece1725c398dd90e24%3A1601946495&click_sum=e56de599&ref=shop_home_active_1&crt=1"
                color="blue-gray"
                className="font-normal transition-colors hover:text-slate-500 focus:text-blue-500"
              >
                Shop
              </Typography>
            </li>
            <li>
              <Typography
                as="a"
                href="/product"
                color="blue-gray"
                className="font-normal transition-colors hover:text-slate-500 focus:text-blue-500"
              >
                Product
              </Typography>
            </li>
            <li>
              <Typography
                as="a"
                href="/contact"
                color="blue-gray"
                className="font-normal transition-colors hover:text-slate-500 focus:text-blue-500"
              >
                Contact Us
              </Typography>
            </li>
          </ul>
        </div>
        <hr className="my-8 border-blue-gray-50" />
        <Typography color="blue-gray" className="text-center font-normal">
          &copy; 2023 Rad Salve. All rights reserved. 
        </Typography>
      </footer>
    </>
  );
};

export default MyFooter;
