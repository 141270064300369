import hand1 from "../photos/hand1.jpg";
import hand2 from "../photos/hand2.jpg";

import logo2 from "../photos/logo2.jpg";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <>
      {/* product page responsive grid with 2 col*/}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 p-4 md:p-8 lg:p-16">
        <div className="flex items-center justify-center">
          <img src={hand1} alt="Your Alt Text" className="w-auto h-85" />
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="text-4xl">Rad Salve</h1>
          <br></br>
          <h2 className="text-xl">
            Natural & Organic Healing Salve for Body and Hands
          </h2>
          <br></br>
          <h3 className="list-disc">
            <ul className="list-disc ml-8">
              <li className="mb-2">
                shallow cuts (including around/under the finger nails)
              </li>
              <li className="mb-2">scrapes (such as when you do a knee bar)</li>
              <li className="mb-2">
                bruises (such as when you bash your knee on a rock
                unintentionally....)
              </li>{" "}
              <li className="mb-2">
                mild burns (i.e. oops I hit the air fryer with my finger while
                grabbing the crispy Brussel sprouts)
              </li>
              <li className="mb-2">
                sore or tender skin (crack climbers and crimp enthusiasts - aka
                masochists - you're welcome)
              </li>
              <li className="mb-2">
                dry and/or cracked skin on hands and feet - mild joint relief
                (too much kilter or moon boarding bruh)
              </li>
            </ul>
            <button class="button-black">
              <Link to="https://www.etsy.com/shop/radicalproductsco/?etsrc=sdt">
                Shop
              </Link>
            </button>
          </h3>
        </div>

        {/* second row */}
        <div className="flex flex-col justify-center">
          <h1 className="text-4xl">Healing Life's Radical Advantures</h1>
          <br></br>
          <h2 className="text-l">
            Organics Ingredients: beeswax, jojoba oil, sweet almond oil, olive
            oil, tea tree essential oil, cinnamon bark essential oil, vanilla
            essential oil, lavender essential oil, calendula extract, arnica
            extract, juniper essential oil
          </h2>
          <br></br>
        </div>
        <div className="flex items-center justify-center">
          <img src={logo2} alt="Your Alt Text" className="w-auto h-85" />
        </div>

        {/* Third row */}
        <div className="flex items-center justify-center">
          <img src={hand2} alt="Your Alt Text" className="w-auto h-90" />
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="text-4xl">Rock on and Stay Radical.</h1>
          <br></br>
          <h2 className="text-l">
            It isn’t greasy, or slimy, nor do you have to take it out of the jar
            to put it on! It absorbs quickly, more like a lotion, but it doesn’t
            ruin you calluses!
          </h2>
          <br></br>
          <br></br>
          <h2 className="text-l">
            You can apply it multiple times a day, for
            repeated days in a row, and even cover with bandaids or bandages. No
            fake or synthetic fragrances, and made entirely with love and hopes
            for your ultimate healing.
          </h2>
          <br></br>
          <br></br>
          <h2 className="text-l">
            Moral of the story: If you work or play
            with your hands, feet, elbows, head, shoulders, knees and toes...
            this stuff is for you.
          </h2>
          <br></br>
        </div>
      </div>
    </>
  );
};

export default Product;
