import salve5 from "../photos/salve5.jpg";
import oil2 from "../photos/oil2.jpg";

import logo2 from "../photos/logo2.jpg";

const Home = () => {
  const backgroundStyles = {
    backgroundImage: `url(${salve5})`,
    height: 600,
  };

  const backgroundStyles2 = {
    backgroundImage: `url(${oil2})`,
    height: 600,
  };

  return (
    <>
      <div
        className="home-container h-screen bg-fixed bg-cover bg-parallax bg-center text-white flex justify-center items-center scroll-smooth"
        style={backgroundStyles}
      >
        <div className="home-text-effect h-full flex flex-col">
          <div className="heroSection w-6/12 h-full flex flex-col justify-center">
            <h1 className="text-7xl font-bold mb-8">Heal. Nourish. Smoothe.</h1>
            <h3 className="text-2xl">Healing Life's Radical Adventures</h3>
            <button className="button-white">Shop</button>
          </div>
        </div>
      </div>

      {/* main section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 p-4 md:p-8 lg:p-16">
        <div className="flex flex-col justify-center">
          <h1 className="text-4xl">Organic.</h1>
          <br></br>
          <h2 className="text-xl">
            Our hand balm is a harmonious blend of ingredients, each chosen for
            its unique external healing properties. No synthetic fragrances, and
            made entirely with love and hopes for your ultimate healing.
          </h2>
          <br></br>
        </div>
        <div className="flex items-center justify-center">
          <img src={logo2} alt="Your Alt Text" className="w-auto h-80" />
        </div>
      </div>

      <div
        className="h-screen bg-fixed bg-cover bg-parallax bg-center text-white mb-16"
        style={backgroundStyles2}
      ></div>
    </>
  );
};

export default Home;
